import React from "react"
import DataTable, { TableColumn } from "react-data-table-component"
import moment from "moment"
import { mdiAccountCashOutline, mdiOpenInNew } from "@mdi/js"
import Icon from "@mdi/react"
import { ISubscription } from "../interfaces/subscription"
import { Link } from "react-router-dom"

interface IProps {
    subscriptions: ISubscription[]
    loading: boolean
    totalRows?: number
    handlePerRowsChange?: any
    handlePageChange?: any
    setSort?: Function
}

function SubscriptionTable(props: IProps) {
    const { subscriptions, loading, totalRows, handlePerRowsChange, handlePageChange, setSort } = props

    const handleSort = (column: any, sortDirection: any) => {
        setSort && setSort(`${sortDirection === "desc" ? "" : "-"}${column.sortField}`)
    }

    const columns: TableColumn<ISubscription>[] = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="action-button">
                        {row.fastspring_id ? (
                            <span onClick={() => window.open(`https://app.fastspring.com/subscription/home.xml?mRef=Subscription:${row.fastspring_id}`, "_blank")}>
                                <Icon className="icon view-content" path={mdiAccountCashOutline} size={1} />
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                )
            },
            minWidth: "100px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Owner",
            cell: (row) => <Link target="_blank" to={`/admin/user-details/${row.owner._id}/1`}>{`${row.owner.lastname}, ${row.owner.firstname}`}</Link>,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "owner.lastname",
        },
        {
            name: "Members",
            cell: (row) => (
                <div>
                    {row.members.map((member) => {
                        return (
                            <div>
                                <Link target="_blank" to={`/admin/user-details/${member._id}/1`}>{`${member.firstname} ${member.lastname}`}</Link>
                            </div>
                        )
                    })}
                </div>
            ),
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Used Seats",
            selector: (row) => row.members.length,
            cell: (row) => <p>{`${row.members.length + 1} of ${row.subscription_type.seats}`}</p>,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Upload limit",
            selector: (row) => row.subscription_type.upload_limit,
            cell: (row) => <p>{`${row.subscription_type.upload_limit} GB`}</p>,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Uploads in period",
            selector: (row) => row.allUploadInPeriod,
            cell: (row) => <p>{`${row.allUploadInPeriod} GB`}</p>,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "allUploadInPeriod",
        },
        {
            name: "Uploads remaining",
            selector: (row) => row.subscription_type.upload_limit - row.allUploadInPeriod,
            cell: (row) => <p>{`${row.subscription_type.upload_limit - row.allUploadInPeriod} GB`}</p>,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Subscription Type",
            cell: (row) => row.subscription_type.name,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "subscription_type.name",
        },
        {
            name: "Status",
            cell: (row) => row.status,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "status",
        },
        {
            name: "Fastspring Id",
            selector: (row) => row.fastspring_id || "",
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "fastspring_id",
        },
        {
            name: "Database Id",
            selector: (row) => row._id || "",
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "_id",
        },
        {
            name: "Payments",
            selector: (row) => row.payments.length || "",
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Trial",
            selector: (row) => (row.trial ? "true" : "false"),
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "trial",
        },
        {
            name: "Expiration",
            selector: (row) => moment(row.expiration).format("MMM DD, YYYY"),
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "expiration",
        },
        {
            name: "Created at",
            selector: (row) => moment(row.createdAt).format("MMM DD, YYYY"),
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "createdAt",
        },
        {
            name: "Savings",
            selector: (row) => row.discount !== undefined  ? row.discount : 0,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "discount",
        },
        {
            name: "Referral link",
            cell: (row) =>
                process.env.REACT_APP_FRONTEND_URL ? (
                    <a href={`${process.env.REACT_APP_FRONTEND_URL}/referral/${row.privateId}`} rel="noreferrer" target="_blank">
                        <Icon className="icon" path={mdiOpenInNew} size={1} />
                    </a>
                ) : (
                    ""
                ),
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "privateId",
        },
    ]

    return handlePerRowsChange && handlePageChange ? (
        <DataTable
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={(page) => props.handlePerRowsChange(page, 0)}
            responsive
            striped
            progressPending={loading}
            columns={columns}
            data={subscriptions}
            onChangePage={(page) => handlePageChange(page - 1)}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
            sortServer
            onSort={handleSort}
        />
    ) : (
        <DataTable responsive striped progressPending={loading} columns={columns} data={subscriptions} />
    )
}

export default SubscriptionTable
