import Stories from "../../components/stories/Stories"

function StoriesPage() {
    return (
        <div>
            <h2>Published Stories</h2>
            <Stories published={true} />
            <h2>Unpublished Stories</h2>
            <Stories published={false} />
        </div>
    )
}

export default StoriesPage
