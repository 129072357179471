import React from "react"
import { Row, Col } from "react-bootstrap"
import default_avatar from "../../assets/images/default-avatar.jpg"
import Icon from "@mdi/react"
import { mdiArrowRightTop, mdiEye } from "@mdi/js"
import { IUserDetails } from "../../interfaces/authentication"
import { profileTypes, userFields, dateFormat } from "../../helper/enum/enum"
import moment from "moment"
import InfoField from "./InfoField"
import { useNavigate } from "react-router-dom"
import DataTable, { TableColumn } from "react-data-table-component"

interface IProps {
    user: IUserDetails
    updateUser: Function
}

function Profile(props: IProps) {
    const navigate = useNavigate()
    // async function removeWebsite(removedSite: string) {
    //     try {
    //         const filteredWebsites = props.user.websites.filter((site) => site !== removedSite)

    //         const response = await instance.put(`/user/${props.user._id}`, { details: { websites: filteredWebsites } })
    //         if (response.status === 200) {
    //             // update the data on the profile info page
    //             props.updateUser("websites", response.data.websites)
    //         } else {
    //         }
    //     } catch (error) {
    //     }
    // }

    const columns: TableColumn<IUserDetails>[] = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="action-button">
                        <span onClick={() => navigate(`/admin/user-details/${row._id}/1`)}>
                            <Icon className="icon view-content" path={mdiEye} size={1} />
                        </span>
                    </div>
                )
            },
            minWidth: "100px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Name",
            cell: (row) => `${row.firstname} ${row.lastname}`,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Id",
            selector: (row) => row._id,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            minWidth: "200px",
            wrap: true,
            sortable: true,
        },
    ]

    return (
        <div className="profile-info">
            <Row className="top-section">
                <Col className="avatar">
                    <img src={props.user.avatar ? props.user.avatar : default_avatar} className="user-picture" alt="user" />
                    {props.user.profileType === profileTypes.DECEASED && (
                        <p className="life-dates">{`${moment(props.user.birthdate).format(dateFormat.SHORT)} to ${moment(props.user.diedon).format(dateFormat.SHORT)}`}</p>
                    )}
                </Col>
            </Row>
            <Row className="section">
                <Col xs={12} className="info-section details">
                    <p className="title">Details</p>
                    <div className="content">
                        <InfoField title="First Name" userid={props.user._id} field={userFields.FIRSTNAME} displayedData={props.user.firstname} updateUser={props.updateUser} />
                        <InfoField
                            title="Middle name"
                            userid={props.user._id}
                            field={userFields.MIDDLENAME}
                            displayedData={props.user.middlename ? props.user.middlename : ""}
                            updateUser={props.updateUser}
                        />
                        <InfoField title="Last Name" userid={props.user._id} field={userFields.LASTNAME} displayedData={props.user.lastname} updateUser={props.updateUser} />
                        <InfoField title="Nickname" userid={props.user._id} field={userFields.NICKNAME} displayedData={props.user.nickname ? props.user.nickname : ""} updateUser={props.updateUser} />
                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField
                                title="Date of birth"
                                userid={props.user._id}
                                field={userFields.BIRTHDATE}
                                displayedData={props.user.birthdate ? moment(props.user.birthdate).format(dateFormat.SHORT) : ""}
                                updateUser={props.updateUser}
                            />
                        )}
                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField title="Country" userid={props.user._id} field={userFields.COUNTRY} displayedData={props.user.country} updateUser={props.updateUser} />
                        )}
                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField title="State/County" userid={props.user._id} field={userFields.STATE} displayedData={props.user.state} updateUser={props.updateUser} />
                        )}
                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField title="Email" userid={props.user._id} field={userFields.EMAIL} displayedData={props.user.email} updateUser={props.updateUser} />
                        )}
                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField title="Telephone" userid={props.user._id} field={userFields.PHONE} displayedData={props.user.phone ? props.user.phone : ""} updateUser={props.updateUser} />
                        )}
                        {props.user.profileType === profileTypes.CHILD && (
                            <InfoField
                                title="Profile type"
                                userid={props.user._id}
                                field={userFields.PROFILETYPE}
                                displayedData="Child (<18 years old)"
                                updateUser={props.updateUser}
                                birthdate={props.user.birthdate}
                            />
                        )}

                        {props.user.profileType !== profileTypes.DECEASED && (
                            <InfoField title="Related websites" userid={props.user._id} field={userFields.WEBSITES} displayedData="" updateUser={props.updateUser} websites={props.user.websites} />
                        )}

                        <div className="related-websites">
                            {props.user.websites &&
                                props.user.websites.map((site, index) => {
                                    return (
                                        <div key={site + index} className="website-item">
                                            <div>
                                                <Icon size={1} path={mdiArrowRightTop} />
                                                <a href={site.includes("http") ? site : `//${site}`} rel="noreferrer" target="_blank">
                                                    {site}
                                                </a>
                                            </div>
                                            {/* <div onClick={() => removeWebsite(site)}>
                                                <Icon size={1} path={mdiCloseCircle} />
                                            </div> */}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>Administrators</h4>
                    <DataTable
                        responsive
                        striped
                        // progressPending={props.loading}
                        columns={columns}
                        data={props.user.administrators}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>Successors</h4>
                    <DataTable
                        responsive
                        striped
                        // progressPending={props.loading}
                        columns={columns}
                        data={props.user.successors}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>"Write about me"</h4>
                    <DataTable
                        responsive
                        striped
                        // progressPending={props.loading}
                        columns={columns}
                        data={props.user.featuredBy}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Profile
