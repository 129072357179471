import axiosClient from "./API"

/**
 * GET calls
 */


export const getPendingsOfUser = async (userId: string) => {
    try {
        const response = await axiosClient.get(`/admin/relation/user/${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}
