import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { IFamilyVip } from "../../interfaces/subscription"
import { sendVIPInvitationEmail } from "../../services/family-vip"
import FamilyVipTable from "../../components/FamilyVipTable"

function FamilyVipSend() {
    const [familyVip, setFamilyVip] = useState<IFamilyVip[]>([])
    const [loading, setLoading] = useState(true)
    const [ email, setEmail ] = useState<string>('');

    const query = async () => {
        setLoading(true)
        try {
            const response = await sendVIPInvitationEmail({email: email})
            setFamilyVip(response)
        } catch (error) {
            toast.error("Failed to get the FamilyVip")
        }
        setLoading(false)
    }

    return (
        <div className="family-email-token-section">
            <div className="group">
                <input placeholder="Email" value={email} onChange={(evt) => {setEmail(evt.target.value);}}></input>
                <label className="top-label">Email</label>
            </div>
            <div className="family-token-button">
                <button className="default-button button-space" onClick={() => {query()}}>Generate token and send email</button>
            </div>                                                        
        </div>
    )
}

export default FamilyVipSend;
