//  Modules from the React eco-system
import React, { useState, useEffect, useRef } from "react"
import { Navigate, Route, Routes } from "react-router"

// Routes
// import dashboardRoutes, { IDashboardRoute } from "../routes/dashboard.route"

import Sidenav from "../components/Sidenav"
import { getAdmin } from "../services/authentication"
import { setAdmin } from "../store/userSlice"
import { IAdmin } from "../interfaces/admin"
import { useDispatch } from "react-redux"
import logout from "../helper/logout"
import adminRoutes from "../routes/adminRoutes"
import NavbarTop from "../components/NavbarTop"

/**
 * the content of the admin page (for logged in admin)
 */
function Layout() {
    const dispatch = useDispatch()

    useEffect(() => {
        getUserData()
    }, [])

    /**
     * fetch the data of the logged in admin
     */
    async function getUserData() {
        try {
            const admin = await getAdmin()
            if (admin && admin.verified) {
                dispatch(setAdmin(admin as IAdmin))
            }
        } catch (error) {
            console.log("error", error)
            logout()
        }
    }

    /**
     * rendered routes for the logged in admin
     */
    const getAllRoute = () => {
        const routes: any = []
        adminRoutes.forEach((route, index) => {
            routes.push(<Route key={index} path={route.path} element={<route.component />} />)
            if (route.submenu.length > 0) {
                route.submenu.forEach((submenu, subindex) => {
                    routes.push(<Route key={subindex} path={submenu.path} element={<submenu.component />} />)
                })
            }
        })
        return routes
    }

    return (
        <div className="admin-layout">
            <div className="sidebar-container">
                <Sidenav />
            </div>
            <div className="content-panel">
                <NavbarTop />
                <div className="content-section"><Routes> {getAllRoute()}</Routes></div>
            </div>
        </div>
    )
}

export default Layout
