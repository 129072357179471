import axiosClient from "./API"

/**
 * GET calls
 */

/**
 * get all stories
 * @returns published stories
 */
export const getAllPublishedStories = async (params: any = {}) => {
    try {
        const response = await axiosClient.get(`/admin/story`, { params })
        return response.data
    } catch (error) {
        throw error
    }
}

/**
 * get users with acces s to the story
 * @returns users and main story infos
 */
 export const getUserAccessStoryAPI = async (storyId: string) => {
     try {
         const response = await axiosClient.get(`/admin/story/access/${storyId}`)
         return response.data
     } catch (error) {
         throw error
     }
 }
