import React from "react"
import { IUserDetails } from "../interfaces/authentication"
import DataTable, { TableColumn, createTheme } from "react-data-table-component"
import moment from "moment"
import { mdiEye, mdiAccountCashOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { useNavigate } from "react-router-dom"

interface IProps {
    users: IUserDetails[]
    loading: boolean
    totalRows: number
    handlePerRowsChange: any
    handlePageChange: any
    setSort: Function
}

function UserTable(props: IProps) {
    const navigate = useNavigate()
    const colWidth = "150px"

    const handleSort = (column: any, sortDirection: any) => {
        props.setSort(`${sortDirection === "desc" ? "" : "-"}${column.sortField}`)
    }

    const columns: TableColumn<IUserDetails>[] = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="action-button">
                        <span onClick={() => navigate(`/admin/user-details/${row._id}/1`)}>
                            <Icon className="icon view-content" path={mdiEye} size={1} />
                        </span>
                        {row.fastspring_account_id ? (
                            <span onClick={() => window.open(`https://app.fastspring.com/2/store/customer.xml?id=${row.fastspring_account_id}`, "_blank")}>
                                <Icon className="icon view-content" path={mdiAccountCashOutline} size={1} />
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                )
            },
            minWidth: "100px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Name",
            cell: (row) => `${row.lastname}, ${row.firstname}`,
            minWidth: colWidth,
            wrap: true,
            sortable: true,
            sortField: "lastname",
        },
        {
            name: "Id",
            selector: (row) => row._id,
            minWidth: colWidth,
            wrap: true,
            sortable: true,
            sortField: "_id",
        },
        {
            name: "Nickname",
            selector: (row) => row.nickname || "",
            minWidth: colWidth,
            wrap: true,
            sortable: true,
            sortField: "nickname",
        },
        {
            name: "Email",
            selector: (row) => row.email,
            minWidth: "200px",
            wrap: true,
            sortable: true,
            sortField: "email",
        },
        {
            name: "Created At",
            selector: (row) => (moment(row.createdAt).isValid() ? moment(row.createdAt).format("MMM DD, YYYY") : ""),
            minWidth: colWidth,
            wrap: true,
            sortable: true,
            sortField: "createdAt",
        },
        {
            name: "Birthdate",
            selector: (row) => (moment(row.birthdate).isValid() ? moment(row.birthdate).format("MMM DD, YYYY") : ""),
            sortable: true,
            sortField: "birthdate",
            minWidth: colWidth,
        },
        {
            name: "Country",
            selector: (row) => row.country,
            minWidth: colWidth,
            wrap: true,
            sortable: true,
            sortField: "country",
        },
        {
            name: "State",
            selector: (row) => row.state,
            minWidth: "100px",
            wrap: true,
            sortable: true,
            sortField: "state",
        },
        {
            name: "Profile Type",
            selector: (row) => row.profileType,
            sortable: true,
            sortField: "profileType",
            wrap: true,
            minWidth: colWidth,
        },
        {
            name: "Last visited",
            selector: (row) => moment(row.lastVisited).format("MMM DD, YYYY"),
            sortable: true,
            sortField: "lastVisited",
            minWidth: colWidth,
        },
        {
            name: "Verified",
            selector: (row) => (row.verified ? "true" : "false"),
            sortable: true,
            sortField: "verified",
            minWidth: "100px",
        },
        {
            name: "Account",
            selector: (row) => (row.subscription ? "subscription" : row.account ? row.account : "free"),
            sortable: false,
            minWidth: colWidth,
        },
    ]

    return (
        <DataTable
            pagination
            paginationServer
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={(page) => props.handlePerRowsChange(page - 1)}
            responsive
            striped
            progressPending={props.loading}
            columns={columns}
            data={props.users}
            onChangePage={(page) => props.handlePageChange(page - 1)}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
            sortServer
            onSort={handleSort}
        />
    )
}

export default UserTable
