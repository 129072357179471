import React, { useState } from "react"
import { Container, Form, Button } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiLock, mdiEye, mdiEyeOff } from "@mdi/js"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setIsAuthenticated } from "../../store/userSlice"
import { login } from "../../services/authentication"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import vertical_logo_and_tagline from "../../assets/images/logo-icon.jpg"

function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
            password: Yup.string().required("Password is a required field"),
        }),
        onSubmit: async (values) => {
            try {
                const cookies = new Cookies()
                const response = await login(values.email, values.password)
                if (response.token && response.refreshToken) {
                    cookies.set("token", response.token, { path: "/" })
                    cookies.set("refresh_token", response.refreshToken, { path: "/" })
                    dispatch(setIsAuthenticated(true))
                    navigate("/admin/dashboard")
                }
            } catch (error) {
                toast.error("Failed to login")
            }
        },
    })

    return (
        <Container className="login-container">
            <div className="login-top-row">
                <img src={vertical_logo_and_tagline} alt="Logo" className="logo-login" />
                <p className="title">Log in</p>
            </div>
            <Form className="login-form" onSubmit={formik.handleSubmit}>
                <Form.Group className="group">
                    <Form.Label className="top-label">Email</Form.Label>
                    <Form.Control
                        className="string-input"
                        type="email"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        name="email"
                        onBlur={formik.handleBlur}
                        autoFocus={true}
                        value={formik.values.email}
                    />
                </Form.Group>
                <Form.Group className="group password-input">
                    <Form.Label className="top-label">Password</Form.Label>
                    <Form.Control
                        className="string-input"
                        onChange={formik.handleChange}
                        name="password"
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                    />
                    <span onClick={() => setShowPassword(!showPassword)}>
                        <Icon className="icon" path={showPassword ? mdiEye : mdiEyeOff} />
                    </span>
                </Form.Group>

                <div className="button-group">
                    <Button disabled={!(formik.values.email.length && formik.values.password.length)} className="login-button default-button" type="submit">
                        <Icon className="icon" path={mdiLock} />
                        Log in
                    </Button>
                </div>
            </Form>
        </Container>
    )
}

export default Login
