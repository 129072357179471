import axiosClient from "./API"

export const login = async (email: string, password: string) => {
    try {
        const body = {
            email,
            password,
        }
        const response = await axiosClient.post("/admin/auth/login", body)
        return response.data
    } catch (error) {
        throw error
    }
}

export const getAdmin = async () => {
    try {
        const response = await axiosClient.get("/admin/auth")
        console.log("RSPONSEEEE", response.data);
        
        return response.data
    } catch (error) {
        throw error
    }
}
