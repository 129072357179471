import React, { useState } from "react"
//react pro sidebar components
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar"
import horizontal_logo from "../assets/images/horizontal-logo.svg"
import logo_icon from "../assets/images/logo-icon.jpg"
import Icon from "@mdi/react"
import { mdiHelpCircle, mdiLock, mdiEye, mdiEyeOff } from "@mdi/js"
import logout from "../helper/logout"
import adminRoutes from "../routes/adminRoutes"
import { Link, matchPath, useLocation } from "react-router-dom"

import { mdiArrowLeftBoldCircleOutline } from "@mdi/js"
import { mdiArrowRightBoldCircleOutline } from "@mdi/js"
import { mdiLogoutVariant } from "@mdi/js"

const Sidenav = () => {
    const location = useLocation()
    //menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)
    //custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
    }

    const checkActive = (routePath: string) => {
        const { pathname } = location
        const match = matchPath(pathname, routePath)
        return match ? true : false
    }

    return (
        <>
            {/* collapsed props to change menu size using menucollapse state */}
            <ProSidebar className="sidenav" collapsed={menuCollapse} breakPoint="md">
                <SidebarHeader>
                    {menuCollapse ? <img src={logo_icon} alt="Logo" className="logo" width="30" height="30" /> : <img src={horizontal_logo} alt="Logo" className="logo" />}

                    <div className="closemenu" onClick={menuIconClick}>
                        {/* changing menu collapse icon on click */}
                        {menuCollapse ? <Icon size={1} className="icon" path={mdiArrowRightBoldCircleOutline} /> : <Icon size={1} className="icon" path={mdiArrowLeftBoldCircleOutline} />}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        {adminRoutes
                            .filter((adminroute) => adminroute.displayOnSidebar)
                            .map((adminRoute, index) => {
                                return adminRoute.submenu.filter((submenu) => submenu.displayOnSidebar).length ? (
                                    <SubMenu key={index} title={adminRoute.name} icon={<Icon size={1} className="icon" path={adminRoute.icon} />}>
                                        {adminRoute.submenu
                                            .filter((submenu) => submenu.displayOnSidebar)
                                            .map((subMenu, subindex) => {
                                                return (
                                                    <MenuItem key={subindex} active={checkActive(subMenu.path)}>
                                                        {subMenu.name}
                                                        <Link to={subMenu.path} />
                                                    </MenuItem>
                                                )
                                            })}
                                    </SubMenu>
                                ) : (
                                    <MenuItem key={index} active={checkActive(adminRoute.path)} icon={<Icon size={1} className="icon" path={adminRoute.icon} />}>
                                        {adminRoute.name}
                                        <Link to={adminRoute.path} />
                                    </MenuItem>
                                )
                            })}
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Menu iconShape="square">
                        <MenuItem onClick={() => logout()}>
                            <Icon size={1} className="icon" path={mdiLogoutVariant} />
                            Logout
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </>
    )
}
export default Sidenav
