import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { IFamilyVip } from "../../interfaces/subscription"
import { getFamilyVip } from "../../services/family-vip"
import FamilyVipTable from "../../components/FamilyVipTable"

function FamilyVip() {
    const [familyVip, setFamilyVip] = useState<IFamilyVip[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getFamilyVipQuery()

        return () => {}
    }, [])

    const getFamilyVipQuery = async () => {
        setLoading(true)
        try {
            const response = await getFamilyVip()
            setFamilyVip(response)
        } catch (error) {
            toast.error("Failed to get the FamilyVip")
        }
        setLoading(false)
    }

    return (
        <div>
            <FamilyVipTable familyVip={familyVip} />
        </div>
    )
}

export default FamilyVip;
