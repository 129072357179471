import SubscriptionTable from "../../components/SubscriptionTable"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { ISubscription } from "../../interfaces/subscription"
import { getSubscriptions } from "../../services/subscriptions"
import { Form, Row, Col, Button } from "react-bootstrap"

function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
    const [loading, setLoading] = useState(true)

    // pagination
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [sort, setSort] = useState("status")

    const [filters, setFilters] = useState({
        status: "",
        fastspring_id: "",
    })

    useEffect(() => {
        getSubscriptionsQuery()
        return () => {}
    }, [sort])

    const getSubscriptionsQuery = async (page: number = 0) => {
        setLoading(true)
        try {
            const response = await getSubscriptions({ limit: perPage, pageNo: page, sort, ...filters })
            setSubscriptions(response.result)
            setTotalRows(response.totalRecords)
        } catch (error) {
            toast.error("Failed to get the subscriptions")
        }
        setLoading(false)
    }

    const handlePageChange = async (page: number) => {
        setLoading(true)
        try {
            getSubscriptionsQuery(page)
        } catch (error) {
            toast.error("Failed to get the users")
        }
        setLoading(false)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true)
        try {
            const response = await getSubscriptions({ limit: newPerPage, pageNo: 0, sort })
            setSubscriptions(response.result)
            setPerPage(newPerPage)
        } catch (error) {
            toast.error("Failed to get the subscriptions")
        }
        setLoading(false)
    }

    const handleInputChange = (field: string, value: string) => {
        setFilters((prev) => {
            return { ...prev, [field]: value }
        })
    }

    const handleSubmit = () => {
        getSubscriptionsQuery(0)
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="subsForm.status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleInputChange("status", e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="subsForm.fastspring_id">
                            <Form.Label>Fastspring id</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleInputChange("fastspring_id", e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <Button onClick={handleSubmit}>Search</Button>
            </Form>
            <SubscriptionTable setSort={setSort} handlePageChange={handlePageChange} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} subscriptions={subscriptions} loading={loading} />
        </div>
    )
}

export default Subscriptions
