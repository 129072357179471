import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { getUsers } from "../../services/users"
import { IUserDetails } from "../../interfaces/authentication"
import UserTable from "../../components/UserTable"
import { Form, Button, Col, Row } from "react-bootstrap"

function Users() {
    const [users, setusers] = useState<IUserDetails[]>([])
    const [loading, setLoading] = useState(true)

    // pagination
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [sort, setSort] = useState("lastname")

    const [filters, setFilters] = useState({
        firstname: "",
        lastname: "",
    })

    useEffect(() => {
        getUsersQuery(0)

        return () => {}
    }, [sort])

    const getUsersQuery = async (page: number) => {
        setLoading(true)
        try {
            const response = await getUsers({ limit: perPage, pageNo: page, sort, ...filters })
            setusers(response.result)
            setTotalRows(response.totalRecords)
        } catch (error) {
            toast.error("Failed to get the users")
        }
        setLoading(false)
    }

    const handlePageChange = async (page: number) => {
        setLoading(true)
        try {
            getUsersQuery(page)
        } catch (error) {
            toast.error("Failed to get the users")
        }
        setLoading(false)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true)
        try {
            const response = await getUsers({ limit: newPerPage, pageNo: page, sort })
            setusers(response.result)
            setPerPage(newPerPage)
        } catch (error) {
            toast.error("Failed to get the users")
        }

        setLoading(false)
    }

    const handleInputChange = (field: string, value: string) => {
        setFilters((prev) => {
            return { ...prev, [field]: value }
        })
    }

    const handleSubmit = () => {
        getUsersQuery(0)
    }

    return (
        <div>
            <p className="title">Users</p>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userForm.firstname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleInputChange("firstname", e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userform.lastname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control onChange={(e) => handleInputChange("lastname", e.target.value)} type="text" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userform.email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control onChange={(e) => handleInputChange("email", e.target.value)} type="text" />
                        </Form.Group>
                    </Col>
                </Row>

                <Button onClick={handleSubmit}>Search</Button>
            </Form>
            <UserTable setSort={setSort} handlePageChange={handlePageChange} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} users={users} loading={loading} />
        </div>
    )
}

export default Users
