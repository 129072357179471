import React from "react"
import { IUserDetails } from "../interfaces/authentication"
import DataTable, { TableColumn, createTheme } from "react-data-table-component"
import moment from "moment"
import { mdiEye } from "@mdi/js"
import Icon from "@mdi/react"
import { useNavigate } from "react-router-dom"
import { IFamilyVip } from "../interfaces/subscription"

interface IProps {
    familyVip: IFamilyVip[]
    // loading: boolean
    // totalRows: number
    // handlePerRowsChange: any
    // handlePageChange: any
}

function FamilyVipTable(props: IProps) {
    const columns: TableColumn<IFamilyVip>[] = [
        {
            name: "Email",
            cell: (row) => row.email,
            minWidth: "150px",
            wrap: true,
            sortable: true,
        },
        {
            name: "Token",
            cell: (row) => row.token,
            minWidth: "150px",
            wrap: true,
            sortable: true,
        },
        {
            name: "Created at",
            selector: (row) => moment(row.createdAt).format("MMM DD, YYYY"),
            minWidth: "150px",
            wrap: true,
            sortable: true,
        },
    ]
    return (
        <DataTable
            pagination
            // paginationServer
            // paginationTotalRows={props.totalRows}
            // onChangeRowsPerPage={(page) => props.handlePerRowsChange(page - 1)}
            responsive
            striped
            // progressPending={props.loading}
            columns={columns}
            data={props.familyVip}
            theme="simpletable"
            // onChangePage={(page) => props.handlePageChange(page - 1)}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        />
    )
}

export default FamilyVipTable
