import axiosClient from "./API"

export const getFamilyVip = async (params: any = {}) => {
    try {
        const response = await axiosClient.get("/admin/subscription/family-vip/list", { params })
        return response.data
    } catch (error) {
        throw error
    }
}

export const sendVIPInvitationEmail = async (params: any = {}) => {
    try {
        const response = await axiosClient.post("/admin/subscription/family-vip/send", params )
        return response.data
    } catch (error) {
        throw error
    }
}