import React, { useState, useEffect } from "react"
import { Spinner, Tab, Tabs } from "react-bootstrap"
import { IUserDetails } from "../../interfaces/authentication"
import { defaultUserData } from "../../interfaces/family"
import { useParams } from "react-router"
import { getUser, updateUser } from "../../services/users"
import Profile from "../../components/user/Profile"
import { toast } from "react-toastify"
import ProfileFamily from "../../components/user/ProfileFamily"
import { useNavigate } from "react-router-dom"
import UserSettings from "./UserSettings"
import ProfileSubscriptions from "../../components/user/ProfileSubscriptions"
import ProfileUploads from "../../components/user/ProfileUploads"

export enum menu {
    PROFILE = "1",
    FAMILY = "2",
    UPLOADS = "3",
    SUBSCRIPTIONS = "4",
    SETTINGS = "5",
}

function UserDetails() {
    // params for the tab menu
    const params = useParams()
    const navigate = useNavigate()

    // user datas
    const [user, setUser] = useState<IUserDetails>(defaultUserData)

    //Loader
    const [isLoading, setIsLoading] = useState<boolean>(false)

    //tabs
    const [key, setKey] = useState<menu>(menu.PROFILE)

    useEffect(() => {
        return () => {}
    }, [])

    useEffect(() => {
        if (params.id) {
            setIsLoading(false)
            getProfileData()
        }
    }, [params.id])

    useEffect(() => {
        if (params.menu && params.menu !== key) {
            setKey(params.menu as menu)
        }
    }, [params.menu])

    async function getProfileData() {
        setIsLoading(true)
        try {
            const userid = params.id || ""
            const userResponse = await getUser(userid)
            setUser(userResponse)
        } catch (error) {
            toast.error("Failed to get the profile data")
        }
        setIsLoading(false)
    }

    const setTeamAccountQuery = async () => {
        try {
            if (params && params.id) {
                await updateUser(params.id, { account: "team" })
                toast.success("The user is updated")
            }
        } catch (error) {
            toast.error("Failed to get the FamilyVip")
        }
    }

    if (isLoading) {
        return <Spinner animation="border" />
    }

    return (
        <div className="profile-container">
            {user && user._id ? (
                <div className="top-container">
                    <div className="profile-name">
                        <p className="title text-center my-4">{`${user.firstname} ${user.lastname}`}</p>
                    </div>
                    <Tabs onSelect={(k) => k && navigate(`/admin/user-details/${user._id}/${k}`)} defaultActiveKey="1" id="fill-tab-example" activeKey={key} className="mb-3" fill>
                        <Tab eventKey={menu.PROFILE} title="Profile">
                            <Profile user={user} updateUser={setUser} />
                            {user.account && user.account === "team" ? null : (
                                <div className="profile-button-set">
                                    <button
                                        className="default-button button-space"
                                        onClick={() => {
                                            setTeamAccountQuery()
                                        }}>
                                        Set Profile to Team Account
                                    </button>
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey={menu.FAMILY} title="Family">
                            <ProfileFamily relations={user.relations} userId={user._id} />
                        </Tab>
                        <Tab eventKey={menu.UPLOADS} title="Uploads">
                            <ProfileUploads uploadsInPeriod={user.uploadsInPeriod} userId={user._id} />
                        </Tab>
                        <Tab eventKey={menu.SUBSCRIPTIONS} title="Subscriptions">
                            <ProfileSubscriptions userId={user._id} fastspringAccountId={user.fastspring_account_id} />
                        </Tab>
                        <Tab eventKey={menu.SETTINGS} title="Settings">
                            <UserSettings settings={user.settings} />
                        </Tab>
                    </Tabs>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default UserDetails
