import React from "react"
import { Col, Row } from "react-bootstrap"
import { ISettings } from "../../interfaces/authentication"

interface IProps {
    settings: ISettings
}

function UserSettings(props: IProps) {
    return (
        <div>
            <h4>Media</h4>
            {Object.entries(props.settings.media).map(([fildName, value]) => {
                return (
                    <Row>
                        <Col xs={2}>
                            <p>{fildName.toUpperCase()}</p>
                        </Col>
                        <Col>
                            <p>{value}</p>
                        </Col>
                    </Row>
                )
            })}
            <h4>Updates</h4>
            {Object.entries(props.settings.updates).map(([fildName, value]) => {
                return (
                    <Row>
                        <Col xs={2}>
                            <p>{fildName.toUpperCase()}</p>
                        </Col>
                        <Col>
                            <p>{value ? "true" : "false"}</p>
                        </Col>
                    </Row>
                )
            })}
            <h4>Language</h4>
            <Row>
                <Col xs={2}>
                    <p>Current language</p>
                </Col>
                <Col>
                    <p>{props.settings.language}</p>
                </Col>
            </Row>
        </div>
    )
}

export default UserSettings
