import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { toast } from "react-toastify"
import { fileTypes } from "../../helper/enum/enum"
import { getUserCountedMediaAPI } from "../../services/users"

interface IProps {
    userId: string
    uploadsInPeriod: number
}

interface uploadCounter {
    [key: string]: number
}

function ProfileUploads(props: IProps) {
    const [countedMedia, setCountedMedia] = useState<uploadCounter>({
        [fileTypes.IMAGE]: 0,
        [fileTypes.AUDIO]: 0,
        [fileTypes.VIDEO]: 0,
        [fileTypes.ATTACHMENT]: 0,
    })

    const getCountedMedia = async () => {
        try {
            const response = await getUserCountedMediaAPI(props.userId)
            response.counted && setCountedMedia(response.counted)
        } catch {
            toast.error("Failed to get the counted medias")
        }
    }

    useEffect(() => {
        getCountedMedia()
    }, [props.userId])

    return (
        <div>
            <h4>Uploads in Period</h4>
            <Row>
                <Col xs={2}>
                    <p>Uploads in the current period</p>
                </Col>
                <Col>
                    <p>{`${props.uploadsInPeriod} GB`}</p>
                </Col>
            </Row>

            <h4>Uploads (all)</h4>
            {Object.entries(countedMedia).map(([fildName, value]) => {
                return (
                    <Row>
                        <Col xs={2}>
                            <p>{fildName.toUpperCase()}</p>
                        </Col>
                        <Col>
                            <p>{value}</p>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default ProfileUploads
