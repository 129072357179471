import React from "react"
import { ToastContainer, toast, Flip } from "react-toastify"
// import Sidenav from "./components/Sidenav"
//  Modules from the React eco-system
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
// pages
import Layout from "./layout/Layout"
import Login from "./pages/authentication/Login"
// redux store
import { RootState } from "./store/store"
import "react-toastify/dist/ReactToastify.css"

function App() {
    // authentication state from the redux
    const { isAuthenticated } = useSelector((state) => (state as RootState).userReducer)
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="*" element={<ProtectedRoute isAuthenticated={isAuthenticated} />}></Route>
                    </Route>
                </Routes>
            </Router>

            <ToastContainer transition={Flip} limit={5} theme="light" position={toast.POSITION.TOP_CENTER} autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover />
        </div>
    )
}

const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    if (!isAuthenticated) {
        return <Navigate to={"/login"} replace />
    }

    return <Layout />
}

export default App
