import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getUserSubscriptions } from "../../services/subscriptions"
import SubscriptionTable from "../SubscriptionTable"
import { ISubscription } from "../../interfaces/subscription"

interface IProps {
    userId: string
    fastspringAccountId?: string
}

function ProfileSubscriptions(props: IProps) {
    const [ownSubscriptions, setownSubscriptions] = useState<ISubscription[]>([])
    const [memberSubscriptions, setMemberSubscriptions] = useState<ISubscription[]>([])
    const [loading, setLoading] = useState(false)

    const getSubscriptions = async () => {
        setLoading(true)
        try {
            const subscriptions = await getUserSubscriptions(props.userId)
            subscriptions && subscriptions.ownedSubscriptions && setownSubscriptions(subscriptions.ownedSubscriptions)
            subscriptions && subscriptions.memberSubscriptions && setMemberSubscriptions(subscriptions.memberSubscriptions)
        } catch {
            toast.error("Failed to get the subscriptions of the user")
        }
        setLoading(false)
    }

    useEffect(() => {
        getSubscriptions()
    }, [props.userId])

    return (
        <div>
            <h4>Fastspring account</h4>
            {props.fastspringAccountId ? (
                <>
                    <a href={`https://app.fastspring.com/2/store/customer.xml?id=${props.fastspringAccountId}`} target="_blank" rel="noopener noreferrer">
                        Account
                    </a>
                </>
            ) : (
                <p>The user does not have Fastspring account.</p>
            )}
            <h4>Own subscriptions</h4>
            <SubscriptionTable subscriptions={ownSubscriptions} loading={loading} />
            <h4>Member of subscriptions</h4>
            <SubscriptionTable subscriptions={memberSubscriptions} loading={loading} />
        </div>
    )
}

export default ProfileSubscriptions
