import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Cookies from "universal-cookie"
import { EAdminRoles, IAdmin } from "../interfaces/admin"

const cookies = new Cookies()

interface IUserState {
    isAuthenticated: boolean
    admin: IAdmin
}

const defaultAdmin: IAdmin = {
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    verified: false,
    avatar: "",
    role: EAdminRoles.SUPERADMIN,
}

const initialState: IUserState = {
    isAuthenticated: cookies.get("token") ? true : false,
    admin: defaultAdmin,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            return { ...state, isAuthenticated: action.payload }
        },
        setAdmin: (state, action: PayloadAction<IAdmin>) => {
            return { ...state, admin: action.payload }
        },
        reset: () => {
            return { ...initialState }
        },
    },
})

export const { setIsAuthenticated, setAdmin, reset } = userSlice.actions
export default userSlice.reducer
