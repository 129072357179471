import axiosClient from "./API"

/**
 * GET calls
 */

export const getUsers = async (params: any = {}) => {
    try {
        const response = await axiosClient.get("/admin/user", { params })
        return response.data
    } catch (error) {
        throw error
    }
}

export const getUser = async (userId: string, params: any = {}) => {
    try {
        const response = await axiosClient.get(`/admin/user/${userId}`, { params })
        return response.data
    } catch (error) {
        throw error
    }
}

export const getUserCountedMediaAPI = async (userId: string) => {
    try {
        const response = await axiosClient.get(`/admin/media/user/${userId}`)

        return response.data
    } catch (error) {
        throw error
    }
}

/**
 * PUT calls
 */

export const updateUser = async (userId: string, updateData: any) => {
    try {
        const body = {
            details: updateData,
        }
        const response = await axiosClient.put(`/admin/user/${userId}`, body)
        return response.data
    } catch (error) {
        throw error
    }
}
