//  Modules from the React eco-system
import React from "react"

// Screens
import Users from "../pages/users/Users"
import Dashboard from "../pages/Dashboard"
import UserDetails from "../pages/users/UserDetails"
import Subscriptions from "../pages/Subscriptions/Subscriptions"
import Settings from "../pages/Settings/Settings"
import StoriesPage from "../pages/Stories/StoriesPage"

// Styles, bootstrap, icons
import {
    mdiChartDonut,
    mdiAccountStarOutline,
    mdiAccountMultipleOutline,
    mdiAccountGroup,
    mdiHumanMaleFemaleChild,
    mdiCogOutline,
} from "@mdi/js"
import FamilyVip from "../pages/FamilyVip/FamilyVip"
import FamilyVipSend from "../pages/FamilyVip/FamilyVipSend"

export interface IAdminRoute {
    path: string
    name: string
    exact: boolean
    icon: string // for the sidebar
    component: React.FunctionComponent
    displayOnSidebar: boolean // if false: it does not displayed on the sidebar
    submenu: IAdminRoute[]
}

const adminRoutes: IAdminRoute[] = [
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        exact: true,
        icon: mdiChartDonut,
        component: Dashboard,
        displayOnSidebar: true,
        submenu: [],
    },
    {
        path: "/admin/users",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: Users,
        displayOnSidebar: true,
        submenu: [
            // {
            //     path: "/admin/users",
            //     name: "User",
            //     exact: true,
            //     icon: mdiAccountMultipleOutline,
            //     component: UserDetails,
            //     displayOnSidebar: true,
            //     submenu: [],
            // },
            {
                path: "/admin/user-details/:id/:menu",
                name: "User Details",
                exact: true,
                icon: mdiAccountMultipleOutline,
                component: UserDetails,
                displayOnSidebar: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/admin/subscriptions",
        name: "Subscriptions",
        exact: true,
        icon: mdiAccountGroup,
        component: Subscriptions,
        displayOnSidebar: true,
        submenu: [
            // {
            //     path: "/admin/subscriptions",
            //     name: "Subscriptions",
            //     exact: true,
            //     icon: mdiAccountGroup,
            //     component: Subscriptions,
            //     displayOnSidebar: true,
            //     submenu: [],
            // },
            {
                path: "/admin/subscription-details/:id",
                name: "Subscription Details",
                exact: true,
                icon: mdiAccountGroup,
                component: Subscriptions,
                displayOnSidebar: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/admin/stories",
        name: "Stories",
        exact: true,
        icon: mdiAccountStarOutline,
        component: StoriesPage,
        displayOnSidebar: true,
        submenu: [],
    },
    {
        path: "/admin/family-vip",
        name: "Family VIP",
        exact: true,
        icon: mdiHumanMaleFemaleChild,
        component: FamilyVip,
        displayOnSidebar: true,
        submenu: [
            {
                path: "/admin/family-vip",
                name: "Family VIP",
                exact: true,
                icon: mdiHumanMaleFemaleChild,
                component: FamilyVip,
                displayOnSidebar: true,
                submenu: [],
            },
            {
                path: "/admin/family-vip/send",
                name: "Family VIP Send",
                exact: true,
                icon: mdiHumanMaleFemaleChild,
                component: FamilyVipSend,
                displayOnSidebar: true,
                submenu: [],
            },
        ],
    },
    {
        path: "/admin/settings",
        name: "Settings",
        exact: true,
        icon: mdiCogOutline,
        component: Settings,
        displayOnSidebar: true,
        submenu: [],
    },    
]

export default adminRoutes
