import React, { useState, useEffect } from "react"
import { IRelation, IPendingRelation } from "../../interfaces/family"
import DataTable, { TableColumn } from "react-data-table-component"
import { mdiEye } from "@mdi/js"
import Icon from "@mdi/react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getPendingsOfUser } from "../../services/relations"

interface IProps {
    relations: IRelation[]
    userId: string
}

function ProfileFamily(props: IProps) {
    const navigate = useNavigate()

    const [pendingFrom, setPendingFrom] = useState<IPendingRelation[]>([])
    const [pendingTo, setPendingTo] = useState<IPendingRelation[]>([])

    const [pendingLoading, setPendingLoading] = useState(true)

    const getPendingRelations = async () => {
        setPendingLoading(true)
        try {
            const pendings = await getPendingsOfUser(props.userId)
            pendings?.relationsFrom && setPendingFrom(pendings.relationsFrom)
            pendings?.relationsTo && setPendingTo(pendings.relationsTo)
        } catch (error) {
            toast.error("Failed to get pending relations")
        }
        setPendingLoading(false)
    }

    useEffect(() => {
        console.log("GETPEND useeffect")

        props.userId && getPendingRelations()
    }, [props.userId])

    const columns: TableColumn<IRelation>[] = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="action-button">
                        <span onClick={() => navigate(`/admin/user-details/${row.userid._id}/1`)}>
                            <Icon className="icon view-content" path={mdiEye} size={1} />
                        </span>
                    </div>
                )
            },
            width: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Relation",
            selector: (row) => row.type,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Name",
            cell: (row) => `${row.userid.firstname} ${row.userid.lastname}`,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Id",
            selector: (row) => row.userid._id,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Email",
            selector: (row) => row.userid.email,
            minWidth: "200px",
            wrap: true,
            sortable: true,
        },
    ]

    const getPendingTable = (direction: string = "from") => {
        const pendingColumns: TableColumn<IPendingRelation>[] = [
            {
                name: "Relation",
                selector: (row) => row.relationType,
                minWidth: "150px",
                wrap: true,
                sortable: false,
            },
            {
                name: "Name",
                cell: (row) => (direction === "from" ? `${row.to.firstname} ${row.to.lastname}` : `${row.from.firstname} ${row.from.lastname}`),
                minWidth: "150px",
                wrap: true,
                sortable: false,
            },
            {
                name: "Email",
                selector: (row) => (direction === "from" ? row.to.email : row.from.email),
                minWidth: "200px",
                wrap: true,
                sortable: true,
            },
        ]

        return (
            <DataTable
                responsive
                striped
                progressPending={pendingLoading}
                columns={pendingColumns}
                data={direction === "from" ? pendingFrom : pendingTo}
            />
        )
    }

    return (
        <div>
            <h4>Relations</h4>
            <DataTable
                responsive
                striped
                // progressPending={props.loading}
                columns={columns}
                data={props.relations}
            />
            <h4>Pending relations (from)</h4>
            {getPendingTable()}
            <h4>Pending relations (to)</h4>
            {getPendingTable("to")}
        </div>
    )
}

export default ProfileFamily
