import React, { useState } from "react"
import { IStory } from "../../interfaces/story"
import DataTable, { TableColumn } from "react-data-table-component"
import moment from "moment"
import { Link } from "react-router-dom"
import { mdiEye } from "@mdi/js"
import Icon from "@mdi/react"
import StoryDetailsModal from "./StoryDetailsModal"

interface IProps {
    stories: IStory[]
    loading: boolean
    totalRows: number
    handlePerRowsChange: any
    handlePageChange: any
    setSort: Function
}

function StoriesTable(props: IProps) {
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedStoryId, setSelectedStoryId] = useState("")

    const handleSort = (column: any, sortDirection: any) => {
        props.setSort(`${sortDirection === "desc" ? "" : "-"}${column.sortField}`)
    }

    const handleShowIconClick = (storyId: string) => {
        setSelectedStoryId(storyId)
        setShowDetailsModal(true)
    }

    const columns: TableColumn<IStory>[] = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="action-button">
                        <span onClick={() => handleShowIconClick(row._id)}>
                            <Icon className="icon view-content" path={mdiEye} size={1} />
                        </span>
                    </div>
                )
            },
            minWidth: "100px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Title",
            selector: (row) => row.title,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "title",
        },
        {
            name: "Author",
            cell: (row) => <Link target="_blank" to={`/admin/user-details/${row.author?._id}/1`}>{`${row.author?.lastname}, ${row.author?.firstname}`}</Link>,
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Created At",
            selector: (row) => (moment(row.createdAt).isValid() ? moment(row.createdAt).format("MMM DD, YYYY") : ""),
            minWidth: "200px",
            wrap: true,
            sortable: true,
            sortField: "createdAt",
        },
        {
            name: "Start Date",
            selector: (row) => moment(row.date.startDate.date).format(`${row.date.startDate.day ? "DD " : ""}${row.date.startDate.month ? "MMM," : ""} YYYY`),
            minWidth: "200px",
            wrap: true,
            sortable: false,
        },
        {
            name: "End Date",
            selector: (row) => (row.date.endDate ? moment(row.date.endDate.date).format(`${row.date.endDate.day ? "DD " : ""}${row.date.endDate.month ? "MMM," : ""} YYYY`) : ""),
            minWidth: "200px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Access Family",
            selector: (row) => row.accessFamily,
            minWidth: "200px",
            wrap: true,
            sortable: true,
            sortField: "accessFamily",
        },
        {
            name: "Access Private",
            selector: (row) => (row.accessPrivate ? "true" : "false"),
            minWidth: "200px",
            wrap: true,
            sortable: true,
            sortField: "accessPrivate",
        },
        {
            name: "Featured",
            cell: (row) => (row.featured ? <Link target="_blank" to={`/admin/user-details/${row.featured?._id}/1`}>{`${row.featured?.firstname} ${row.featured?.lastname}`}</Link> : ""),
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Co-authors",
            cell: (row) => (
                <div>
                    {row.coAuthors.map((coauthor) => {
                        return (
                            <div>
                                <Link target="_blank" to={`/admin/user-details/${coauthor._id}/1`}>{`${coauthor.firstname} ${coauthor.lastname}`}</Link>
                            </div>
                        )
                    })}
                </div>
            ),
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Participants",
            cell: (row) => (
                <div>
                    {row.participants.map((participant) => {
                        return (
                            <div>
                                <Link target="_blank" to={`/admin/user-details/${participant._id}/1`}>{`${participant.firstname} ${participant.lastname}`}</Link>
                            </div>
                        )
                    })}
                </div>
            ),
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Story tags",
            selector: (row) => row.storyTags.join(", "),
            minWidth: "150px",
            wrap: true,
            sortable: false,
        },
        {
            name: "Id",
            selector: (row) => row._id,
            minWidth: "150px",
            wrap: true,
            sortable: true,
            sortField: "_id",
        },
    ]

    return (
        <>
            <DataTable
                pagination
                paginationServer
                paginationTotalRows={props.totalRows}
                onChangeRowsPerPage={(page) => props.handlePerRowsChange(page - 1)}
                responsive
                striped
                progressPending={props.loading}
                columns={columns}
                data={props.stories}
                theme="simpletable"
                onChangePage={(page) => props.handlePageChange(page - 1)}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                onSort={handleSort}
            />
            <StoryDetailsModal storyId={selectedStoryId} show={showDetailsModal} handleClose={() => setShowDetailsModal(false)} />
        </>
    )
}

export default StoriesTable
