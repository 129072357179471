import React, { useState, useEffect } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { IUserDetails } from "../../interfaces/authentication"
import { getUserAccessStoryAPI } from "../../services/stories"

interface IProps {
    show: boolean
    handleClose: Function
    storyId: string
}

interface IStoryDetails {
    total: number
    accessType: string
    author: IUserDetails
    title: string
    createdAt: Date
    access: IUserDetails[]
    published: boolean
}

/**
 * display story details
 * @param props
 * @returns
 */
function StoryDetailsModal(props: IProps) {
    const [storyDetails, setStoryDetails] = useState<IStoryDetails>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        props.storyId && getStoryDetails()

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.storyId])

    const getStoryDetails = async () => {
        setIsLoading(true)
        try {
            const storyDetails = await getUserAccessStoryAPI(props.storyId)
            setStoryDetails(storyDetails)
        } catch (error) {
            toast.error("Failed to get story details")
        }
        setIsLoading(false)
    }

    return (
        <Modal show={props.show} onHide={() => props.handleClose()} className="story-details-modal">
            <Modal.Header closeButton>
                <Modal.Title>Story Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!storyDetails || isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <>
                        <h4 className="story-title">{storyDetails.title}</h4>
                        <h6>Author</h6>
                        <p>
                            <Link target="_blank" to={`/admin/user-details/${storyDetails.author?._id}/1`}>{`${storyDetails.author.lastname}, ${storyDetails.author.firstname}`}</Link>
                        </p>
                        <h6>Access type</h6>
                        <p>{storyDetails.accessType}</p>
                        <h6>Published</h6>
                        <p>{storyDetails.published ? "True" : "False"}</p>
                        <h6>{`Users with access (${storyDetails.total})`}</h6>
                        {storyDetails.published ? <></> : <span>After publish...</span>}
                        <div className="access-users">
                            {storyDetails.access.map((user) => {
                                return (
                                    <div className="access-user-container">
                                        <Link className="access-user" key={user?._id} target="_blank" to={`/admin/user-details/${user?._id}/1`}>{`${user.lastname}, ${user.firstname}`}</Link>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default StoryDetailsModal
