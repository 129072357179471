import axiosClient from "./API"

export const getSubscriptions = async (params: any = {}) => {
    try {
        const response = await axiosClient.get("/admin/subscription", { params })
        return response.data
    } catch (error) {
        throw error
    }
}

/**
 * get the subscriptions of one user
 * @param userid 
 * @returns 
 */
export const getUserSubscriptions = async (userid: string) => {
    try {
        const response = await axiosClient.get(`/admin/subscription/${userid}`)
        return response.data
    } catch (error) {
        throw error
    }
}
