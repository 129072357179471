import { profileTypes } from "../helper/enum/enum";
import { IUserDetails } from "./authentication";

/**
 * Global
 */
export interface IPendingInvitation {
    picture:string
    name:string
    email:string
    location?:string
    recommendedRelation:string
}

export interface IInvitation {
    _id?:string
    inviter:string
    relationType:string
    email:string
    explanation:IExplanation
    suggestions:string[]
}

export interface IExplanation {
    reasons:IOption[]
    message:string
    examples:IOption[]
}

export interface IPendingRelation {
    from:IUserDetails
    to:IUserDetails
    relationType: string
    suggestions:string[]
}

export interface IPendingRelationDB extends IPendingRelation {
    _id:string
    from:IUserDetails
    to:IUserDetails
}

export interface IRelation {
    _id:string
    createdAt:string
    updatedAt:string
    type:string
    userid:IUserDetails
    group: string
}

export interface IOption {
    _id:string
    text:string
    img:string
    editable?:boolean
}

export const defaultUserData:IUserDetails = {
    _id:"",
    firstname:"",
    profileType:profileTypes.ADULT,
    lastname:"",
    country:"",
    state:"",
    phone: "",
    email:"",
    relations:[],
    groups:[],
    administrators:[],
    successors:[],
    featuredBy:[],    
    websites: [],
    readLaterStories: [],
    lastVisited: new Date(),
    favorites: {
        media:[],
        stories:[]
    },
    settings: {
        updates: {
            monthly: false
        },
        games: {
            welcome: true,
            engage: true
        },
        media: {
            image: "",
            video: "",
            audio: ""
        },
        language: "en",
        other: []
    },
    credit:0.0,
    media_boost:0.0,
    uploadsInPeriod:0.0
}

/**
 * Local
 */
