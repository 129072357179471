import axios from "axios"
import Cookies from "universal-cookie"
import logout from "../helper/logout"

const cookies = new Cookies()

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
})

// request interceptor --> add token to the request
axiosClient.interceptors.request.use(function (config) {
    const token = cookies.get("token")
    if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : ""

        return config
    }
})

// Add a response interceptor
axiosClient.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        // get the original request and the refresh_token from the cookies
        const originalRequest = error.config
        const refresh_token = cookies.get("refresh_token")

        if (!refresh_token) {
            // if the refresh token is not exist logout the admin
            logout()
        }

        // When response code is 401, try to refresh the token.
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest && refresh_token) {
            originalRequest._retry = true

            try {
                // axios request (this is not the axiosClient to avoid the interceptor loop if the refresh request is also 401 )
                const response = await axios.post(process.env.REACT_APP_API_URL + "/admin/auth/refresh", { refresh_token })
                // save the new token
                cookies.set("token", response.data.token, { path: "/" })
                cookies.set("refresh_token", response.data.refreshToken, { path: "/" })

                // add the new token to the original request
                originalRequest.headers.Authorization = `Bearer ${response.data.token}`
                return axios(originalRequest)
            } catch (error) {
                // if the refresh was unsuccessful logout the admin
                logout()
            }
        }

        throw error
    }
)

export default axiosClient
