export enum EAdminRoles {
    SUPERADMIN = "superadmin",
}

export interface IAdmin {
    _id: string
    firstname: string
    lastname: string
    email: string
    password?: string
    phone?: string
    verified: boolean
    avatar?: string
    role: EAdminRoles
}
