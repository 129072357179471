import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./assets/css/styles.scss"
import store from "./store/store"
import { Provider } from "react-redux"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
            <App />
        {/* </React.StrictMode> */}
    </Provider>
)
