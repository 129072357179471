import React, { useState, useEffect } from "react"
import { getAllPublishedStories } from "../../services/stories"
import { IStory } from "../../interfaces/story"
import { toast } from "react-toastify"
import StoriesTable from "./StoriesTable"
import { Col, Row, Form, Button } from "react-bootstrap"

interface IProps {
    published: boolean
}

function Stories(props: IProps) {
    const [stories, setPublishedStories] = useState<IStory[]>([])
    const [loading, setLoading] = useState(true)

    // pagination
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [sort, setSort] = useState("")

    const [filters, setFilters] = useState({
        firstname: "",
        lastname: "",
        title: "",
    })

    const getStories = async (page: number) => {
        setLoading(true)
        try {
            const response = await getAllPublishedStories({ limit: perPage, pageNo: page, sort, ...filters, published: props.published })
            setPublishedStories(response.stories.result)
            setTotalRows(response.stories.totalRecords)
        } catch {
            toast.error("Failed to get stories")
        }
        setLoading(false)
    }

    useEffect(() => {
        getStories(0)
        return () => {}
    }, [sort])

    const handlePageChange = async (page: number) => {
        setLoading(true)
        try {
            getStories(page)
        } catch (error) {
            toast.error("Failed to get the stories")
        }
        setLoading(false)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true)
        try {
            const response = await getAllPublishedStories({ limit: newPerPage, pageNo: page, sort })
            setPublishedStories(response.result)
            setPerPage(newPerPage)
        } catch (error) {
            toast.error("Failed to get the stories")
        }

        setLoading(false)
    }

    const handleInputChange = (field: string, value: string) => {
        setFilters((prev) => {
            return { ...prev, [field]: value }
        })
    }

    const handleSubmit = () => {
        getStories(0)
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userform.title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control onChange={(e) => handleInputChange("title", e.target.value)} type="text" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userForm.firstname">
                            <Form.Label>Author First Name</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleInputChange("firstname", e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="userform.lastname">
                            <Form.Label>Author Last Name</Form.Label>
                            <Form.Control onChange={(e) => handleInputChange("lastname", e.target.value)} type="text" />
                        </Form.Group>
                    </Col>
                </Row>

                <Button onClick={handleSubmit}>Search</Button>
            </Form>
            <StoriesTable setSort={setSort} handlePageChange={handlePageChange} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} stories={stories} loading={loading} />
        </div>
    )
}

export default Stories
