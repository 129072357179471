import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

function NavbarTop() {

  // authentication state from the redux
  const { admin } = useSelector((state) => (state as RootState).userReducer);

  return (
    <Navbar>
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: <a className="logged-in-username" href="#login">{`${admin.firstname} ${admin.lastname}`}</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarTop;
